import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { Props as PostProps } from './post';
import Hero from '../hero';
import Layout from '../layout';
import Seo from '../seo';
import Hr from '../hr';
import padding from '../../util/padding';
import { a } from 'gatsby-plugin-google-analytics';

export type Props = {
  data: {
    mdx: {
      frontmatter: {
        title: string;
        hero?: PostProps['data']['mdx']['frontmatter']['hero'];
      };
      body: string;
    };
  };
};

type MdxLinkProps = {
  href: string;
};

const MdxLink = ({ href, ...rest }: MdxLinkProps) => {
  const isExternal =
    /^https?:\/\//.test(href) || /\.pdf$/.test(href) || /^mailto:/.test(href);

  return isExternal ? (
    <a href={href} {...rest} target="_blank" />
  ) : (
    <Link to={href} {...rest} />
  );
};

const components = {
  a: MdxLink,
  hr: Hr,
};

const DefaultPage = ({ data: { mdx } }: React.PropsWithChildren<Props>) => {
  const { body } = mdx;
  const { title, hero } = mdx.frontmatter;

  return (
    <Layout withHero={Boolean(hero)}>
      <Seo title={title} />
      {hero ? (
        <Hero
          {...hero.file.childImageSharp.fluid}
          darken={hero.darken}
          unsplashUser={hero.unsplashUser}
          author={hero.author}
          title={title}
          position={hero.position}
        />
      ) : null}
      <div css={padding}>
        <h1>{title}</h1>
        <MDXProvider components={components}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </div>
    </Layout>
  );
};

export default DefaultPage;

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      excerpt
      frontmatter {
        title
        hero {
          darken
          author
          unsplashUser
          position
          file {
            childImageSharp {
              fluid(quality: 75, jpegProgressive: true) {
                src
                srcSet
              }
            }
          }
        }
      }
      body
    }
  }
`;
